/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import $ from 'jquery'; // added this to try to get it working
import 'bootstrap'

//import 'bootstrap/dist/js/bootstrap.bundle';
//import $ from 'jquery';
//import { createPopper } from '@popperjs/core';
/*
document.addEventListener('DOMContentLoaded', function() {
  const popperElement = document.getElementById('popper-element');
  const triggerButton = document.getElementById('popper-trigger');

  if (popperElement && triggerButton) {
    createPopper(triggerButton, popperElement, {
      placement: 'top', // Popper will appear on top
    });
  }
});
*/

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
//import "channels"; // was never working

//import channels from 'channels'; // doesn't work because channels are in the wrong place

//import { adminUtility } from './utility';


import {  siteUi } from './siteUi';
import {  generate } from './generate';
import './posts'; // Import posts.js
//import './photos'; // Import photos.js
import 'easy-autocomplete';

Rails.start();
Turbolinks.start();
ActiveStorage.start();