// app/javascript/packs/posts.js

import 'bootstrap';
import Rails from '@rails/ujs';

/**
 * ignore the edits made in the editor, show the original section and remove the html of the editor
 * @param {*} postId 
 * @param {*} editorElement 
 * @param {*} refreshSection if true, refresh the section
 */
function cancelPostEdits(postId,editorElement,refreshSection=true) {
  let section = $(`section[data-id='${postId}']`);
  let editPostSection = section.find('.edit_post');
  let editableWrap = section.find('.editable-wrap');
  editableWrap.removeClass('d-none');
  editPostSection.html('');

//  const el = $(editorElement)
  //let wrap = el.closest('section').find('.editable-wrap').removeClass('d-none')
  //let editPostSection = section.find('.edit_post').html('')
  siteUi.scrollToEl(section)
  if(refreshSection) {
    refreshPost(postId)
  }
}
function savePostEdits(postId,details,editorElement,refreshSection=true)
{
  console.log('inside Save Content')
  const saveContentPath = `/posts/${postId}/save_content`;

  //showSavingIndicator();

  // Simulate a delay to show the loading indicator (remove in production)
  
    const payload = {details:details}  // title: title
    const el = $(editorElement)
    el.closest('.editorSaving').show()
    $.ajax({
      url: saveContentPath,
      method: 'POST',
      data: {
        post: payload
      },
      success: function(response) {
        // Handle success
        //showSaveSuccess();
        el.closest('.saving').hide()
        if(refreshSection) {
          const updatedMessage = { header: 'Changes saved', message: 'Section is now refreshing...', type: 'success' } 
          siteUi.showNotification(updatedMessage);           
          refreshPost(postId)

        }
      },
      error: function(error) {
        // Handle error
        console.error('Error during save:', error);
        el.closest('.editorSaving').hide()
        el.closest('.editorError').html('Error saving!')
      },
    });
  
};

//$('.summernote').summernote();
/*
   tinymce.init({
    selector: selector,
    plugins: [ 'quickbars' ],
    toolbar: false,
    menubar: false,
    inline: true
  });
  */

  function initTextEdit(selector = '.editable-field', html = null) {
    console.log('inside initTextEdit');

// Initialize an array to store Trumbowyg instances
var trumbowygInstances = [];

// Loop through each element matching the selector
$(selector).each(function(index, element) {
  // Initialize Trumbowyg for the current element
  var trumbowygInstance = $(element).trumbowyg({
    html: html,
    btnsDef: {
      customSave: {
        fn: function() {
          // Access the specific Trumbowyg instance for this button
          var currentTrumbowygInstance = trumbowygInstances[index];
          
          // Access data-id and HTML content using currentTrumbowygInstance
          var dataId = currentTrumbowygInstance.data('id');
          var htmlContent = currentTrumbowygInstance.trumbowyg('html');

          // Example of using the data-id and HTML content
          console.log('Data ID:', dataId);
          console.log('HTML Content:', htmlContent);

          savePostEdits(dataId, htmlContent,currentTrumbowygInstance,true);
        },
        ico: 'save', // not working
        title: 'Save Changes',
        hasIcon:false,
      },
      customCancel: {
        fn: function() {
          // Access the specific Trumbowyg instance for this button
          var currentTrumbowygInstance = trumbowygInstances[index];
          // Access data-id and HTML content using currentTrumbowygInstance
          var dataId = currentTrumbowygInstance.data('id');
          console.log('Data ID:', dataId);
          cancelPostEdits(dataId,currentTrumbowygInstance,false);
        },
        ico: 'cancel', // not working
        title: 'Cancel',
        hasIcon:false,
      },
    },
    btns: [
      ['viewHTML'],
      ['customSave'],
      ['customCancel'],
      ['undo', 'redo'], // Only supported in Blink browsers
      ['formatting'],
      ['strong', 'em', 'del'],
      ['superscript', 'subscript'],
      ['link'],
      //['insertImage'],
      ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
      ['unorderedList', 'orderedList'],
      ['horizontalRule'],
      ['removeformat'],
      ['fullscreen'],
    ],
    autogrow: true,
  });

  // Save the current Trumbowyg instance to the array

  if(html!==null)
  {
    //$(trumbowygInstance)
    trumbowygInstance.trumbowyg('html',html)
  }

  trumbowygInstances.push(trumbowygInstance);
});
}

document.addEventListener('turbolinks:load', function() {
  console.log('turbolinks loaded')
  window.siteUi.bindBlogSections()

  console.log('binding SectionToolbars')
  bindSectionToolbars()
  //initTextEdit()
  
});

document.addEventListener('DOMContentLoaded', function () {
  console.log('binding SectionToolbars 2')
  //bindSectionToolbars() // redundant
});
function bindSectionToolbars() {
  document.body.addEventListener('click', function (event) {
    var button = event.target.closest('.section-tools .customCollapse');
    if (button) {
      event.preventDefault(); // Prevent the default behavior of the link

      // Find the corresponding target based on data-bs-target
      var targetSelector = button.getAttribute('data-bs-target');
      var target = document.querySelector(targetSelector);

      if (!target) {
        console.log('No target found for the clicked button.');
        return;
      }

      console.log('Found target:', target);
      console.log('ID target:', target.id);

      // Toggle the 'show' class for the specific target
      console.log('Before toggle:', target.classList.contains('show'));
      let hidePanel = target.classList.contains('show') // true if it is already showing
      target.classList.toggle('show');
      console.log('After toggle:', target.classList.contains('show'));

      // Hide other subpanels within the same section
      const targetParent = target.closest('.edit-tools')
      var otherTargets = Array.from(targetParent.querySelectorAll('.collapse-section.show'))
        .filter(otherTarget => otherTarget !== target);
      otherTargets.forEach(otherTarget => otherTarget.classList.remove('show'));
      

      console.log(`Hide PANEL: ${hidePanel}`)
      // not needed if it's working properly
      /*if(hidePanel) {
        setTimeout(() => {
          target.classList.remove('show');
        },700)
      }
      */
      

    }
  });
}


function renderKeywords(newTags)
{
  siteUi.showNotification('Keywords generated');
    console.log(newTags)
    renderTagifyTags(newTags,true)
    // Get the existing Tagify instance on the input

    siteUi.hidePageOverlay({ id: 'renderKeywords' });
}

function renderTagifyTags(newTags,tryAgain=false)
{
  var input = document.getElementById('keywords-input');
  var tagify = input ? input.tagify || new Tagify(input) : null;

  // Check if tagify is defined before attempting to use it
  if (tagify) {
    // Add the new tags to the Tagify instance
    tagify.addTags(newTags);
    // Update the original form field value
    input.value = tagify.value;
  } else {
    console.error('Tagify is not defined or the input element is not found.');
    setTimeout(()=>{renderTagifyTags(newTags),500}) // retries once
  }
}

function renderOutline(sections) {
  console.log('inside renderOutline')
    const mainModalId = 'outlineSectionsModal';
    const saveButtonId = 'saveOutline';
    // Populate the modal with title options
    const outlineSectionsList = document.getElementById('outlineSectionsList');
    //const titleInput = document.getElementById('title-input');
    outlineSectionsList.innerHTML = sections.map(option => `<li data-html_tag="${option.html_tag}" data-text="${option.section}" class="d-flex align-items-top justify-content-between"><a href="#" class="title-option border-left border-start border-2"><span class="fw-bold me-3 text-info">${option.html_tag}</span>${option.section}</a><button type="button" class="btn bg-none btn-sm d-inline-block delete-btn text-danger">&times;</button></li>`).join('');
    console.log('binding outline')

    // Show the modal
    $('#outlineSectionsModal').modal('show');
    updateInput();

    $("#buildFromOutline").off("click", "#" + saveButtonId).on("click", "#" + saveButtonId, function(e) {   
      const newSections = getOutlineSections();
      const loadingLabel = `Generating ${newSections.length} blog sections`;
      siteUi.simulatePageLoading()
      //TODO: sections.length is NOT updated
      $('#' + mainModalId).modal('hide');
      $('#' + mainModalId + 'Compare').modal('hide');
      
      siteUi.showPageOverlay(loadingLabel)

      let delayIncr = 1500;
      let delayMs = 0;
      newSections.forEach(section => {
        setTimeout(() => {
          let sectionLoadingLabel = `${loadingLabel}: ${section.text}`
          siteUi.showPageOverlay(sectionLoadingLabel)

        },delayMs)
        delayMs+=delayIncr
      })

      setTimeout(()=>{
        window.siteUi.hidePageOverlay()
      },10000)
    })

    $("#buildFromOutline").off("click", ".modifyOutlineBtn").on("click", ".modifyOutlineBtn", function(e) {
      e.stopPropagation();
      console.log(`shortenOutline clicked: ${$(this).data("instructions")} `)
      changeOutline($(this).data("instructions"))
    })

    $("#outlineSectionsList").sortable({
      // Options
      update: function(event, ui) {
        updateInput();
      }
    });

    // Handle deletion when the delete button is clicked
    $("#outlineSectionsList").off("click", ".delete-btn").on("click", ".delete-btn", function() {
      $(this).closest("li").remove();
      updateInput();
    });

    bindModifyOutlineForm()
    
      // Optional: Handle drop events or additional interactions
      // $("#outlineSectionsList").droppable({
      //   drop: function(event, ui) {
      //     // Handle the drop event
      //     console.log("Dropped!");
      //   }
      // });

    siteUi.hidePageOverlay({ id: 'renderOutline' });
}

// return {id, text, html_tag}
function getOutlineSections() {
  var sections = [];
  $("#outlineSectionsList li").each(function(index) {
    var id = $(this).data("id");
    var html_tag = $(this).data("html_tag");
    var text = $(this).data("text").trim();
//        var text = $(this).text().trim();
    sections.push({ id: id, text: text, html_tag: html_tag });
  });
  return sections
}
// Function to update the hidden input field

function updateInput() {
  // Update the hidden input field with JSON string
  $("#updatedSectionsInput").val(JSON.stringify(getOutlineSections()));
}

function renderTitles(titleOptions, postId) {
  // Populate the js check for nulmodal with title options
  const titleOptionsList = document.getElementById('titleOptionsList');
  let titleInput;
  
  console.log(`INSIDE renderTitles: ${postId}`);
  
  if (postId) {
    console.log('spot1');
    titleInput = document.getElementById('title-input');
  } else {
    console.log('spot2');
    const autoPostForm = document.getElementById('autoPostForm');
    titleInput = autoPostForm.querySelector(".title-input");
  }
  const animationCss = 'animate__animated animate__bounceInRight';
  const titleOptionsHTML = titleOptions.map((option, index) => `<li class="${animationCss}" style="animation-delay: ${index * 100}ms;"><a href="#" class="title-option">${option}</a></li>`).join('');
  titleOptionsList.innerHTML = titleOptionsHTML;

//  const titleOptionsHTML = titleOptions.map(option => `<li><a href="#" class="title-option">${option}</a></li>`).join('');
//  titleOptionsList.innerHTML = titleOptionsHTML;
  
  // Show the modal with animation
  const titleOptionsModal = $('#titleOptionsModal');
  bindModifyTitlesForm()
  titleOptionsModal.modal('show').addClass(animationCss);
  
  // Remove animation classes after a delay
  setTimeout(() => {
    titleOptionsModal.removeClass(animationCss);
  }, 4000);

  const slideInIncrement = 100;
  let slideInDelay = 0;
  // Attach click event listener to each title option
  const titleOptionElements = document.querySelectorAll('.title-option');
  titleOptionElements.forEach(titleOption => {

    // TODO: ADD CODE to individually animate the .title-option elements 
    // The first one should slide in immediately, second one 100ms later, 3rd one 100ms later, etc...

    titleOption.addEventListener('click', (e) => {
      e.preventDefault();
      // Update the post[title] input with the selected title
      titleInput.value = titleOption.textContent;
      // Highlight the change temporarily
      titleInput.classList.add('highlight-change');
      setTimeout(() => {
        titleInput.classList.remove('highlight-change');
      }, 2500); // Adjust the duration as needed

      // Hide the modal
      $('#titleOptionsModal').modal('hide');
      $('#titleOptionsModalCompare').modal('hide')
      $("#titleOptionsModalCompare").hide()
      setTimeout(()=>{
        $("#titleOptionsModalCompare").remove()
      },500)

      
      //$('#titleOptionsModalCompare').remove()
      
      if (postId) {
        // For unsaved posts, don't try to update them
        updatePost({ post: { id: postId, title: titleOption.textContent } }, `/posts/${postId}.js`);
      }
      else {
        //siteUi.showNotification("saving new post")
        // TODO: save tone and word length
        const createData = {multi:1, auto:1,post: {title: titleOption.textContent,target_word_count: $("#post_target_word_count").val(),target_tone:$("#post_target_tone").val() }}
        createPost(createData)
      }
    });
  });

  siteUi.hidePageOverlay({ id: 'renderTitles' });
}

/*
document.addEventListener('turbolinks:load', () => {
  $(document).on('ajax:success', '#postBuilder', (event) => {
    console.log('ajax success')
    const data = event.detail[0]; // Extract data from the event
    // Handle the response data here
    console.log('post creator called')
  });
});
*/

document.addEventListener('turbolinks:load', () => {
    const input = document.querySelector('.tags');
  
    if (input && !window.Tagify) {
      const tagifyScript = document.createElement('script');
      tagifyScript.src = 'https://cdn.jsdelivr.net/npm/@yaireo/tagify';
      tagifyScript.onload = function () {
        new Tagify(input);
      };
      document.head.appendChild(tagifyScript);
    }
  
    // Add event listener for form submission
    // postBuilder contains the autoPost buttons and keyword input
    // the form only has id and keywords-input
    const form = document.getElementById('postBuilder');
    if (form) {
      form.addEventListener('submit', generate.handleGeneratePostFormSubmit);
    }

    const autoPostForm = document.getElementById('autoPostForm');
    if (autoPostForm) {
      autoPostForm.addEventListener('submit', generate.handleAutoPostSubmit);
    }

    bindToggleEditTools()
  });
  
 // Sync UI to the checkbox
  function syncToggleEditTools() {
    var editCheckbox = document.getElementById('editCheckbox');
    if(editCheckbox) {
      var isEditing = editCheckbox.checked;
      var editingClass = 'editing';
      var displayStyle = isEditing ? 'block' : 'none';

      document.querySelectorAll('.blog-post').forEach(function (element) {
        element.classList.toggle(editingClass, isEditing);
      });

      document.querySelectorAll('.edit-tools').forEach(function (element) {
        element.style.display = displayStyle;
      });
    }
  }
  /**
   * show the toolbars to edit many sub posts (and blogs)
   */
  function bindToggleEditTools() {
    syncToggleEditTools()
      var editCheckbox = document.getElementById('editCheckbox');
      if(editCheckbox) {
        editCheckbox.addEventListener('change', function () {
          syncToggleEditTools()
        });
      }
  }

    function changeOutline(instructions) {
      const progressBarContainer = document.getElementById('progress-bar-container');
      siteUi.showLoadingEffects()

      siteUi.showNotification(`Starting outline modification: ${instructions}`);    
      const postId = $("#outlineSectionsList").data("post");
      const outlineSections = $("#updatedSectionsInput").val()
      const formData = {post: {sections: outlineSections}}
      const url = `/posts/${postId}/generate_outline?edit=1&instructions=${instructions}`
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
        //body: JSON.stringify(formData),
        body: JSON.stringify({ post: { sections: JSON.parse(outlineSections) } }),
      })
        .then(response => response.json())
        .then(data => {
          // Handle the response from the server based on the button clicked
          if (instructions === 'shorter') {
            renderOutline(data.outline)   
            siteUi.showNotification('Outline shortened');           
          } else if (instructions === 'longer') {
            renderOutline(data.outline)
            siteUi.showNotification('Outline lengthened');
          }
          // Hide the loading effects in case of an error
          window.siteUi.hideLoadingEffects();
  
          progressBarContainer.style.display = 'none';
        })
        .catch(error => {
          console.error('Error:', error);
        });
  }


  function createPost(createData,outline=true)
  {
    siteUi.showPageOverlay({label:"Saving the new post",id:'createPost'});

    const url = '/posts.json';
    fetch(url, {
      method: 'POST', // Use PATCH for updates
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCSRFToken() // Make sure to include the CSRF token
      },
      body: JSON.stringify(createData)
    })
      .then(response => response.json())
      .then(data => {
        console.log('inside createPost SUCCESS')
        console.log(data);
        
        const postId = data.id
        // call refreshPost(postId,{auto_preview:1,skip_update:1})
        
        siteUi.showNotification('Post created');
        console.log('show Notification called inside createPost')

        // this SHOULD update the postPreview div
        refreshPost(postId,{auto_preview:1,suggest_img_q:1,generate_outline:1});
      })
      .catch(error => {
        console.error(error);
        siteUi.hidePageOverlay({ id: 'createPost' });
        // Handle error
      });
  }
  function updatePost(updateData,url) {
    console.log('updatePost called')
    fetch(url, {
      method: 'PATCH', // Use PATCH for updates
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCSRFToken() // Make sure to include the CSRF token
      },
      body: JSON.stringify(updateData)
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        console.log('show Notification called')
        siteUi.showNotification('Post updated');
        // Handle success
      })
      .catch(error => {
        console.error(error);
        // Handle error
      });
  }

function getCSRFToken() {
  const tokenTag = document.querySelector('meta[name="csrf-token"]');
  return tokenTag ? tokenTag.content : '';
}

// shows feedback when an image search is performed
  function bindAjaxImageSearchFeedback() {
    var form = $('.sectionPhotoSearchForm');
    let savingHtml = `<div class="imgSearchSpinner spinner-grow spinner-grow-sm text-dark mx-1" role="status"><span class="visually-hidden">Searching...</span></div>`;
    if(form) {
      form.on('submit', function(event) {
        //event.preventDefault();
        let spinner = $(this).find('.imgSearchSpinner')
        if(spinner.length == 0) {
          $(this).prepend(savingHtml)
        }
//        form.prepend(savingHtml) // this was getting all forms
        //Rails.fire(form[0], 'submit');
      });
    }
  }

  function bindAjaxAddImage()
  {
    bindAjaxImageSearchFeedback()
    const createNewButtons = document.getElementsByClassName('create-new-button')
    for (var i = 0; i < createNewButtons.length; i++) {
      createNewButtons[i].style.display = 'none';
    }

    const addToButtons = document.querySelectorAll('.add-to-button')
    addToButtons.forEach(addToButton => {
        addToButton.addEventListener('click', (e) => {
            e.preventDefault()
            e.stopPropagation()
            
            // get closest .img-search-wrap
            // collapse a bootstrap element
            var imgSearchWrap = $(addToButton).closest('.img-search-wrap');
            
            // Check if the imgSearchWrap element exists
            if (imgSearchWrap.length) {
                // Collapse the element using Bootstrap classes
                imgSearchWrap.collapse('hide'); 
            }
            addImageByUrl(addToButton)   
        })
    })
  }
    
    function generateSectionContent(section, data={}) {
      const postId = section.id;
      const refreshingMessage = { header: 'Generating content', message: `The section ${section.title} is being generated`, type: 'starting', imageUrl: null } 
      let showNotice = siteUi.showNotification(refreshingMessage,7000);         
      const progressBarContainer = document.getElementById('progress-bar-container');
      progressBarContainer.style.display = 'block';

      let url = `/posts/${postId}/generate_content.js`

      fetch(url, {
      method: 'POST',
      headers: {
          'Accept': 'application/javascript',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
      //body: JSON.stringify(formData),
      body: JSON.stringify(data), // NOTE: this is not showing up on the server
    })
    .then(response => response.text()) // assuming the response is text
    .then(script => {
          // Evaluate the received JavaScript
          eval(script);
          siteUi.hideNotification(showNotice)
          const refreshingDoneMessage = { header: 'Generation done', message: 'Content was written...check it out!', type: 'success', imageUrl: null } 
          siteUi.showNotification(refreshingDoneMessage);           
          // Hide the loading effects in case of an error
          //window.siteUi.hideLoadingEffects();

          progressBarContainer.style.display = 'none';
      })
      .catch(error => {
        console.error('Error:', error);
        siteUi.hideNotification(showNotice)
        const refreshingErrorMessage = { header: 'Error generating content', message: 'Try refreshing the page', type: 'error', imageUrl: null } 
        siteUi.showNotification(refreshingErrorMessage,10000);      
      });
    }
       
    function refreshPost(postId,data={})
    {
      console.log(`inside refreshPost: ${postId}`)
      let section = document.querySelector(`section[data-id='${postId}']`)
      const loadingOverlay = section ? section.querySelector('.loading-overlay') : null;
      if(loadingOverlay) {
        console.log('loadingOverlay is found')
        const overlayLoadingLabels = loadingOverlay.querySelectorAll('.loading-spinner-label')  
        const linkLoadingLabel = 'Refreshing content'; //element.dataset.loading;
        console.log(linkLoadingLabel)
        overlayLoadingLabels.forEach(overlayLoadingLabel => {
          overlayLoadingLabel.innerHTML = linkLoadingLabel;
        })
        loadingOverlay.style.display = 'block';
      }
      $(section).find('.editable-wrap').removeClass('d-none')

      const refreshingMessage = { header: 'Refreshing', message: 'The changes are being applied', type: 'starting', imageUrl: null } 
      siteUi.showNotification(refreshingMessage,7000);         

      const progressBarContainer = document.getElementById('progress-bar-container');
      progressBarContainer.style.display = 'block';

      let url = `/update/blog/${postId}.js?skip_update=1`
      if (data && Object.keys(data).length > 0) {
        // Append data values to the URL as query parameters
        url += '&' + Object.entries(data).map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');
      }
      fetch(url, {
      method: 'POST',
      headers: {
          'Accept': 'application/javascript',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
      //body: JSON.stringify(formData),
      body: JSON.stringify(data), // NOTE: this is not showing up on the server
    })
    .then(response => response.text()) // assuming the response is text
    .then(script => {
          // Evaluate the received JavaScript
          eval(script);
          const refreshingDoneMessage = { header: 'Refreshed', message: 'The changes were applied', type: 'success', imageUrl: null } 

          siteUi.showNotification(refreshingDoneMessage);           
          // Hide the loading effects in case of an error
          //window.siteUi.hideLoadingEffects();

          progressBarContainer.style.display = 'none';
          siteUi.hidePageOverlay({ id: 'createPost' });
          if(loadingOverlay) {
            loadingOverlay.style.display = 'none';
          }
      })
      .catch(error => {
        console.error('Error:', error);
        const refreshingErrorMessage = { header: 'Error refreshing', message: 'Try refreshing the page', type: 'error', imageUrl: null } 
        siteUi.showNotification(refreshingErrorMessage,10000);   
        siteUi.hidePageOverlay({ id: 'createPost' });   
      });
  }
    
  function addImageByUrl(btn)
  {
      let remoteImageUrl = btn.dataset.remoteImageUrl
      const oid = btn.dataset.oid;
      const otype = btn.dataset.otype;
      const targetData = {remote_image_url: remoteImageUrl,oid: oid, otype: otype}
      addImageByUrlToObject(targetData)
  }

  /**
   * Send info to the server to trigger the download of a remote Image.
   * Changes the UI right away, so it looks good. Notifies the user of this activity via Notification.
   * @param {*} data {oid,otype,remote_image_url}
   */
  function addImageByUrlToObject(targetData) {

      //siteUi.showNotification('Saving image'); 
      const savingImageMessage = { header: 'Saving Image', message: 'The image will update when done!', type: 'starting', imageUrl: targetData.remote_image_url } 
      siteUi.showNotification(savingImageMessage,15000);         

      const progressBarContainer = document.getElementById('progress-bar-container');
      // Trigger the loading effects
      window.siteUi.showLoadingEffects();
      window.siteUi.simulatePageLoading()    
      progressBarContainer.style.display = 'block';
      
      const url = `/image/add_by_url/${targetData.otype}/${targetData.oid}`

  // update the image immediately, TODO: if the save has an error, notify the creator
  const tempImage = `<img class="header-img header-img-md" src="${targetData.remote_image_url}">`
  const tempImageTarget = $(`.editable-section[data-id=${targetData.oid}]`)
  const headerImgWrap = tempImageTarget.find(".header-img-wrap").eq(0)
  const headerImg = $(headerImgWrap).find('.header-img')
  if(headerImg.length > 0) {
    console.log('headerImg FOUND')
    headerImg.attr("src",targetData.remote_image_url) // .replaceWith(tempImage) //.html(tempImage)
  }
  else {
    console.log('headerImg NOT FOUND')
    console.log(tempImage)
    headerImgWrap.html(tempImage)
  }
  headerImgWrap.closest('header').removeClass('missingImage')
  //var sectionWrap = $(addToButton).closest('section')

  siteUi.scrollToEl(tempImageTarget)

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
      //body: JSON.stringify(formData),
      body: JSON.stringify(targetData),
    })
      .then(response => response.json())
      .then(data => {
          const updatedMessage = { header: 'Image saved', message: 'Section is now refreshing...', type: 'success', imageUrl: targetData.remote_image_url } 
          siteUi.showNotification(updatedMessage);           
          // Hide the loading effects in case of an error
          window.siteUi.hideLoadingEffects();

            progressBarContainer.style.display = 'none';
            refreshPost(targetData.oid)
      })
      .catch(error => {
        console.error('Error:', error);
        alert('scrollToEl error')
      });

  }

    
    // use a negative duration to leave the overlay up (no timeout)
  function showLoading(isNewPost,duration=30000) {
      var loadingOverlay = document.getElementById("loadingOverlay");
      loadingOverlay.style.display = "flex";
      const label = document.querySelector('.loading-spinner-label')
      if(isNewPost)
      {
          label.innerHtml = "Post being created..."
      }
      else {
          label.innerHtml = "Post being updated..."
      }

      // Simulate a delay (you can remove this in a real scenario)
      if(duration > 0) {
          setTimeout(function() {
              loadingOverlay.style.display = "none";
          }, duration); // Adjust the duration as needed
      }
  }

//TODO: implement getting the section ID
function imageSearch(postId,query) {
  console.log(`inside imageSearch q: ${query}`)
  // put the query into the input if it is blank
  // conduct the search by triggering a click?
  $(".post-search-img").each(function(idx) {
    console.log($(this).val())
    // add //IF val == empty
    $(this).val(query)
  })
  //$(".toolAddImage").trigger("click")
  $(".img-search-wrap").collapse('show')
  //$('.sectionPhotoSearchForm').submit();
  
  // NOTE, this will only do the first form
  var form = $('.sectionPhotoSearchForm');
  let savingHtml = `<div class="spinner-grow spinner-grow-sm text-dark" role="status"><span class="visually-hidden">Loading...</span></div>`;
  if(form) {
    form.on('submit', function(event) {
      event.preventDefault();
      $(this).prepend(savingHtml)
      //Rails.fire(form[0], 'submit');
    });
    if(form[0]) {
      Rails.fire(form[0], 'submit');
    }
  }
 
//  form.submit()
}

function renderTitlesNotice() {
  siteUi.showPageOverlay({label:"Potential titles are being generated...based on the keywords and current title",id:'renderTitles'});
  }

  function renderOutlineNotice() {
    siteUi.showPageOverlay({label:"Outline is being generated...based on the keywords and current title",id:'renderOutline'});
  }
  function renderKeywordsNotice() {
    siteUi.showPageOverlay({label:"Keywords are being generated...based on the existing keywords and current title",id:'renderKeywords'});
  }

/*
$(.sectionPhotoSearchForm).on('submit', function(event) {
  event.preventDefault(); // Prevent the default form submission
  Rails.fire(this, 'submit'); // Use Rails UJS to submit as an AJAX request
});
*/

// Exports
window.posts = {
  renderTitles,renderTitlesNotice,
  renderOutline,renderOutlineNotice,getOutlineSections,
  renderKeywords,renderKeywordsNotice,
  bindAjaxAddImage,bindAjaxImageSearchFeedback, refreshPost, addImageByUrl, addImageByUrlToObject, 
  showLoading,
  generateSectionContent,imageSearch,
  bindToggleEditTools, syncToggleEditTools,
  initTextEdit
};