// Debounce function
function debounce(func, delay) {
    let timeoutId;
    return function () {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(func, delay);
    };
  }
  
  function loadPage(url){
    $.ajax({ url: url,
          type: 'GET',
          dataType: 'script', //'json',
          beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
          success: (response) => {
            //
      bindAfterLoad()
          },
          error: (response) => {
            //
          }
      });
  }
  
  function loadGet(url)
  {
    $.ajax({ url: url,
      type: 'GET',
      dataType: 'script', //'json',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      success: (response) => {
        //
      },
      error: (response) => {
        //
      }
    });
  }
  
  function bindAfterLoad() {
      window.bindSavingPopups()
      window.readMoreModule.bindReadMoreButtons()
      //$('.text').each(function(){ if($(this).height() > 100){ $(this).readmore({ speed: 75, collapsedHeight: 100, lessLink: '<a href="#">Read less</a>' });; } });
      window.readMoreModule.bindReadMoreWraps()
  }
  
  function getRemotePage(url,requestType = 'GET',dataType='json') {
    return new Promise((resolve, reject) => {
    $.ajax({ url: url,
            type: requestType,
            dataType: dataType,
            beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
            success: (response) => {
              return resolve({response:response})
            },
            error: (response) => {
              //alert(`Error fetching ${url}`)
              console.log(`Error fetching ${url}`)
              console.log(response)
              return reject({msg:'ERROR ajaxPromise 1',error:response})
            }
        });
    })
    return reject({msg:`RARE SERVER ERROR fetching {$url}`,error:'ERROR INFO'})
  }
  
  
  
  
  function bindLoveBtn() {
    $(".loveBtn").off('click').on('click',function(e) {
      console.log('blb clicked');
      if($(this).data('prevent'))
        e.preventDefault()
      
      let bookId = $(this).data('oid')
      let scopeKey = $(this).data('scope')
      scopeKey = scopeKey == null ? "love" : scopeKey
      let engageUrl = flagBookPath;      
      let iconEl = $(this).find('i')
      if(!engageUrl.includes('?'))
      {
        engageUrl+='?blb=1';
      }
      if(iconEl.hasClass('far'))
      {
        engageUrl+=`&flag=${scopeKey}`
        iconEl.removeClass('far').addClass('fa')
      }
      else {
        engageUrl+=`&unflag=${scopeKey}`
        iconEl.addClass('far').removeClass('fa')
      }
      engageUrl = engageUrl+`&id=${bookId}`
      loadGet(engageUrl)
    })
  }
  
  function bindLovePostBtn() {
    $(".lovePostBtnGlobal").off('click').on('click',function(e) {
      if($(this).data('prevent')) {
        e.preventDefault()
        e.stopImmediatePropagation()
      }
      let target = $(this).closest('.engageWrap').find('.engageInfo')
      target.addClass(animateCssClass)
      setTimeout(()=>{
          target.removeClass(animateCssClass)
      },3000)
    })
  
    $(".lovePostBtn").off('click').on('click',function(e) {
      if($(this).data('prevent')) {
        e.preventDefault()
        e.stopImmediatePropagation()
      }
      
      let postId = $(this).data('oid')
      let otype = $(this).data('otype') // default is Post
      let requestId = $(this).data('arid')
      let scopeKey = $(this).data('scope')
      scopeKey = scopeKey == null ? "love" : scopeKey
      let engageUrl = flagPostPath;      
      let iconEl = $(this).find('i')
      
      if(iconEl.hasClass('far'))
      {
        engageUrl+=`?flag=${scopeKey}`
        iconEl.removeClass('far').addClass('fa')
      }
      else {
        engageUrl+=`?unflag=${scopeKey}`
        iconEl.addClass('far').removeClass('fa')
      }
      console.log('about to flag post')
      console.log(requestId)
  
      if(postId)
        engageUrl = engageUrl+`&id=${postId}`
      if(requestId)
        engageUrl = engageUrl+`&arid=${requestId}`
      if(otype)
        engageUrl = engageUrl+`&otype=${otype}`
  
      loadGet(engageUrl)
    })
  }
    
  
  function bindLoveMessageBtn() {
    $(".loveMessageBtnGlobal").off('click').on('click',function(e) {
      if($(this).data('prevent'))
        e.preventDefault()
      let target = $(this).closest('.engageWrap').find('.engageInfo')
      target.addClass(animateCssClass)
      setTimeout(()=>{
          target.removeClass(animateCssClass)
      },3000)
    })
  
    $(".loveMessageBtn").off('click').on('click',function(e) {
      if($(this).data('prevent'))
        e.preventDefault()
      
      let postId = $(this).data('oid')
      let scopeKey = $(this).data('scope')
      scopeKey = scopeKey == null ? "love" : scopeKey
      let engageUrl = flagRoomMessagePath;      
      let iconEl = $(this).find('i')
      
      if(iconEl.hasClass('far'))
      {
        engageUrl+=`?flag=${scopeKey}`
        iconEl.removeClass('far').addClass('fa')
      }
      else {
        engageUrl+=`?unflag=${scopeKey}`
        iconEl.addClass('far').removeClass('fa')
      }
      engageUrl = engageUrl+`&id=${postId}`
      loadGet(engageUrl)
    })
  }
  
  
  function showSiteOffer() {
      var currentDate = new Date();
      var millisSince = currentDate - shownAt;
      var secondsSince = Math.round(millisSince / 1000);
      
      if(!offerShown || secondsSince > showAfter) {
          $("#siteOffer").modal('show')
          offerShown = true
          shownAt = new Date();
          showAfter = 20
      }
      else {
          console.log(`leave detected, offer skipped: ${secondsSince}`)
      }
      
  }
  
  
  function setupShowBestOffer() {  
      var offerShown = false;
      var shownAt = new Date();
      var showAfter = 0;
         
      $(function(){
          var mouseY = 0;
          var topValue = 0;
          window.addEventListener("mouseout",function(e){
              mouseY = e.clientY;
              if(mouseY<topValue) {
                  showSiteOffer()
              }
          },
          false);
      });
  }
  
  
  function bindMainMenu() {
    setTimeout(()=>{
      console.log('bindMainMenu')
      $("#mainLoadingDiv").css("display", "none");
    },200)
    
      $(".navbar-nav .nav-item .nav-link").off("click").on("click",function(e) {
  
        $(".navbar-nav .nav-item .nav-link").removeClass('active active-u')
        $(this).addClass('active active-u')
        /$("#mainLoadingDiv").css("display", "block");
        if(!progressRunning) {
          
          progressRunning=true
          changeMainProgressBar(0,true)
        }
      })    
  }
    
  
  function scrollToEl(el,delay=2000) {
      let subMenuBuffer = 60
      let newTopPos = el.offset().top - navBarHeaderHeightNum() - subMenuBuffer
  
      $([document.documentElement, document.body]).animate({
          scrollTop: newTopPos
      }, delay);
  }
    
  
  function getElementStyle(elem, name) {
    if (elem.style[name]) {
        return elem.style[name];
    }
    else if (elem.currentStyle) {
        return elem.currentStyle[name];
    }
    else if (document.defaultView && document.defaultView.getComputedStyle) {
        name = name.replace(/([A-Z])/g, "-$1");
        name = name.toLowerCase();
        s = document.defaultView.getComputedStyle(elem, "");
        return s && s.getPropertyValue(name);
    }
    else {
        return null;
    }
  }
    
  
    
  // removes "read more" button on click
  /*
  function bindReadMoreButtons() {
      $(".readMoreBtn").on("click",function(e) {
        e.preventDefault()
        e.stopPropagation()
        $(this).closest('.readMoreWrap').find('.readMore').removeClass('readMore')
        $(this).hide().removeClass('d-flex')
        objEngaged($(this))
      })
  }
  // removes .readMore if height is < 50 OR height > 150
  function bindReadMoreWraps() {
      $('.readMoreWrap').each(function(){ 
      let ht1 = $(this).height()
      let clonedObject = getClone($(this))
      let estimatedHeight = clonedObject.height()
      
      $(".tempDivClone").remove()
      //console.log(`HEIGHT: ${$(this).height()}`)
      //console.log(`EXPANDED HEIGHT: ${estimatedHeight}`)
      //if(estimatedHeight < ht1 || ht1 < 50) { // ht1 < 150) {
      if((estimatedHeight - $(this).height()) < 20) {
        $(this).removeClass('readMore')
        $(this).find('.readMoreBtn').hide().removeClass('d-flex')
      }
    
    //  var expanded = parseInt(element.getBoundingClientRect().height) <= element.readmore.collapsedHeight;
      if($(this).height() > 400) {
        $(this).find('.readMore').removeClass('readMore')
        $(this).find('.readMoreBtn').hide().removeClass('d-flex')
      }
    
    });
  }
  */
  
  function hlProfileByIdx(idx)
  {
      let hlIdx = 0
      let totalItems = parseInt($('.hlProfile').length)
      //console.log(totalItems)
      //console.log(`${idx} index`)
      if(idx >= totalItems)
      {
        idx = 0
        //console.log(`${idx} index is CHANGED`)
      }
      else {
        //console.log(`${idx} is less than ${totalItems}`)
        //console.log(`${idx} index UNCHANGED`)
      }
      if(isBrowserMobile) {
        hlProfileToggleMobile(hlIdx,idx)
      } else {
        hlProfileToggle(hlIdx,idx)
      }
     
      return idx
  }
  
  // loop through profiles to highlight
  
  function hlProfiles(hlIdx,hlProfileCount) {
      var hlDelay = isBrowserMobile ? "1600" : "1000";
      //console.log(`${hlIdx} hlIdx`)
    
      //console.log('hlProfile')
      hlIdx = hlProfileByIdx(hlIdx)
      if(hlLoops < 8) {
        setTimeout(()=> {
          hlProfiles(hlIdx+1,hlProfileCount)
        },hlDelay)
      }
      else {
        $('.hlProfile').removeClass('bg-light').removeClass('border-dark').addClass('bg-white')
        $('.quickSignup').removeClass('bg-white').addClass('bg-light').addClass('border-dark').addClass('animate__animated animate__pulse animate__repeat-3')
      }
      hlLoops = hlLoops + 1
  }
    
  function hlProfileToggle(hlIdx,idx) {
      $('.hlProfile').removeClass('bg-light').removeClass('bg-white')
      $('.hlProfile').each(function() {
          if(hlIdx == idx) {
              $(this).removeClass('bg-white').addClass('bg-light').addClass('border-dark')
          }
          else {
              $(this).removeClass('bg-light').addClass('bg-white').removeClass('border-dark')
          }
          hlIdx+=1
      })
  }
    
  function hlProfileToggleMobile(hlIdx,idx) {
    $('.hlProfile').addClass('d-none').removeClass('bg-light').removeClass('bg-white')
    $('.hlProfile').each(function() {
      if(hlIdx == idx) {
          $(this).removeClass('d-none').removeClass('bg-white').addClass('bg-light').addClass('border-dark')
      }
      else {
          $(this).addClass('d-none').removeClass('bg-light').addClass('bg-white').removeClass('border-dark')
      }
      hlIdx+=1
    })
  }
    
  function setupOnboarder() {
    //$("#onboarder").hide()
    let om = $("#onboardModal")
    if(om.length) {
      
    setTimeout(()=>{
        om.modal("show")
    },300)
        
      om.on('hidden.bs.modal', function (event) {
          $("#onboarder").show()
          $("#onboarder").addClass('animate__animated animate__fadeIn')
      })
    }
  }
  
  function expanderDoExpand(target)
  {
      let prev = target.previousElementSibling;
      prev.style.height = prev.scrollHeight + "px";
      target.style.display = "none";
  }
  
  function showLoadingEffects() {
    // Show loading effects, such as gradient or color transition and a progress indicator
    // You can implement the visual effects here, e.g., changing the background color, displaying a progress bar, etc.
    // For simplicity, let's use a CSS class to change the background color
    document.body.classList.add('loading');
    }
  
    function hideLoadingEffects() {
    // Hide or reset the loading effects
    // Remove the loading class or reset any visual changes
    document.body.classList.remove('loading');
    }
  
  function simulatePageLoading() {
    console.log('inside simulatePageLoading')
    const progressBar = document.getElementById('progress-bar');
  
    // Simulate loading progress
    let progress = 0;
    const interval = setInterval(() => {
      progress += Math.random() * 10; // Simulate variable progress
      updateProgressBar(progress);
  
      if (progress >= 100) {
        clearInterval(interval);
        // Add additional logic when loading is complete
        // For example, reveal the main content, hide the progress bar, etc.
        setTimeout(() => {
          hideProgressBar();
        }, 300);
      }
    }, 200);
  
    // Update the progress bar width
    function updateProgressBar(percentage) {
      progressBar.style.width = `${percentage}%`;
    }
  
    // Hide the progress bar
    function hideProgressBar() {
      const progressBarContainer = document.getElementById('progress-bar-container');
      progressBarContainer.style.opacity = 0;
      setTimeout(() => {
        progressBarContainer.style.display = 'none';
      }, 300);
    }
  }
  
  function showPageOverlay(optionsOrLabel) {
    let label, id;
    if (typeof optionsOrLabel === 'object') {
      ({ label, id } = optionsOrLabel);
    } else {
      label = optionsOrLabel;
    }
  
    const loadingOverlay = document.querySelector('.loading-overlay-fixed');
    const overlayLoadingLabel = loadingOverlay.querySelector('.loading-spinner-label')
    
  
    if(label)
      overlayLoadingLabel.innerHTML = label;
    if(loadingOverlay)
    {
      loadingOverlay.style.display = 'flex';
      if(id)
        loadingOverlay.dataset.loading_id = id;
    }
  }
  function hidePageOverlay(options = null) {
    const loadingOverlay = document.querySelector('.loading-overlay-fixed') //.page-loading-overlay');
    const overlayLoadingLabel = loadingOverlay.querySelector('.loading-spinner-label')
    
    let id, timeout;
  
    if (typeof options === 'object') {
      ({ id, timeout } = options);
    } else {
      id = null;
      timeout = null;
    }
    console.log(`INSIDE hide page Overlay: ${options}`)
    console.log(`id: ${id}`)
  
    if(loadingOverlay)
    {
      if(id) // only hide the overlay if it matches the data-loading-id value
      {
        console.log('if TRUE CASE')
        if(id == loadingOverlay.dataset.loading_id)
          loadingOverlay.style.display = 'none';
      }
      else {
        loadingOverlay.style.display = 'none';
        console.log('ELSE CASE')
      }    
    }
  }
  
  // a simple function so any section with editable-form and editable-wrap can use overlays
  // .editable-wrap or .editable-link INSIDE of .editable-section or .editable-wrap
  function bindBlogSections() {
    bindDraggablePhotos()
    const linksAndForms = document.querySelectorAll('.editable-link, .editable-form');
  
    linksAndForms.forEach(element => {
      const sectionWrap = element.closest('.editable-section');
      const section = sectionWrap ? sectionWrap.querySelector('.editable-wrap') : element.closest('.editable-wrap');
      if(section) {
        const loadingOverlay = section.querySelector('.loading-overlay');
        if(loadingOverlay) {
          const overlayLoadingLabels = loadingOverlay.querySelectorAll('.loading-spinner-label')      
  
          element.addEventListener('ajax:beforeSend', function() {
            console.log('before Send')
            const linkLoadingLabel = element.dataset.loading;
            console.log(linkLoadingLabel)
            overlayLoadingLabels.forEach(overlayLoadingLabel => {
              overlayLoadingLabel.innerHTML = linkLoadingLabel;
            })
  
            // Show loading overlay
            loadingOverlay.style.display = 'block';
            // Disable the link (or other buttons if needed)
            element.classList.add('disabled'); // Use a class to style and handle the disabled state
          });
  
          element.addEventListener('ajax:complete', function() {
            // Hide loading overlay
            loadingOverlay.style.display = 'none';
  
            // Re-enable the link (or other buttons if needed)
            element.classList.remove('disabled');
          });
        }
      }
    });
  }
  
  function bindDraggablePhotos() 
  {
      //window.util.photos.bindDraggables()
      $(document).ready(function () {
        if(jQuery().draggable) {
        $('.draggable-item').draggable({
          revert: 'invalid',
          helper: 'clone',
          cursor: 'grab',
          start: function (event, ui) {
            $(this).addClass('draggable-item-dragging');
          },
          stop: function (event, ui) {
            $(this).removeClass('draggable-item-dragging');
          }
        });
    
        $('.drop-section').droppable({
          accept: '.draggable-item',
          drop: function (event, ui) {
            console.log('inside of DROP')
            console.log(event)
            console.log(ui)
            
            var droppedItem = ui.helper.clone();
            $(this).append(droppedItem);
            // Retrieve data attributes from the dropped item
            var droppedItemData = {oid:droppedItem.data('id'),otype:droppedItem.data('type')}
            var currentDropSection = $(this);
            if(droppedItem.data('image'))
            {
              imageUrl = droppedItem.data('image')
            }
            var dataId = currentDropSection.data('id')
            var dataType = currentDropSection.data('type')
    
    
          // Retrieve the URL of the image inside the dropped item
          if(imageUrl == null)
          {
            imageUrl = droppedItem.find('img').attr('src');
          }
          console.log('Dropped item INTO section with data-id:', dataId, 'data-type:', dataType, 'image URL:', imageUrl);
          const targetData = {remote_image_url: imageUrl,oid: dataId, otype: dataType}
          posts.addImageByUrlToObject(targetData)
          $(this).removeClass('drop-section-hover');
    
    
            // Additional logic as needed
          },
          over: function (event, ui) {
            $(this).addClass('drop-section-hover');
          },
          out: function (event, ui) {
            $(this).removeClass('drop-section-hover');
          }
        });
      }
      });
    
    
  }
  // timeout can also be 'no', in which case it has to be clicked
  // options.explore is a link to explore in more details (ie an API request, etc...)
  function showNotification(optionsOrMessage, timeout = 4000) {
      // Create notification container if it doesn't exist
    const containerId = 'notification-container';
    let notifyContainer = document.getElementById(containerId);
    if (!container) {
      notifyContainer = document.createElement('div');
      notifyContainer.id = containerId;
      document.body.appendChild(notifyContainer);
    }
    
    // Create notification element
    const notification = document.createElement('div');
    notification.className = 'notification';
  
    let message, header, type, imageUrl, rate, dismiss, exploreHref;
  
    // Check if optionsOrMessage is an object
    if (typeof optionsOrMessage === 'object') {
      ({ message, header, type, imageUrl, rate, dismiss, exploreHref } = optionsOrMessage);
    } else {
      message = optionsOrMessage;
      dismiss = 'close'
    }
    let imageElement;
    if (imageUrl) {
      imageElement = document.createElement('img');
      imageElement.className = 'notification-image';
      imageElement.src = imageUrl;  
    }
  
    // Set content based on provided options
    if (header) {
      const headerElement = document.createElement('div');
  
      headerElement.className = 'notification-header';
      headerElement.textContent = header;
      if(imageElement)
        headerElement.appendChild(imageElement);
      notification.appendChild(headerElement);
    }
    else {
      if(imageElement)
        notification.appendChild(imageElement);
    }
  
    if (type) {
      notification.classList.add(type);
    }
  
  
    const messageElement = document.createElement('div');
    messageElement.className = 'notification-message';
    messageElement.textContent = message;
    notification.appendChild(messageElement);
  
      // Create explore link
      if (exploreHref) {
        const exploreLink = document.createElement('a');
        exploreLink.className = 'notification-explore-link';
        exploreLink.textContent = 'Discuss';
        exploreLink.href = exploreHref;
        exploreLink.style.position = 'absolute';
        exploreLink.style.bottom = 0;
        exploreLink.style.right = 0;
        exploreLink.style.padding = '6px';
        exploreLink.target = '_blank'; // Open in a new tab
        notification.appendChild(exploreLink);
      }
      
  
    // Create close button
    const closeButton = document.createElement('span');
    closeButton.className = 'notification-close-button';
    closeButton.textContent = '×';
  
    // Append close button to notification
    notification.appendChild(closeButton);
     
    if (rate) {
      const starRater = document.createElement('div');
      starRater.className = 'starRaterWrap'
      starRater.textContent = //"[Rate 1-5 start]"
      notification.classList.add('starRater')
      notification.appendChild(starRater)
    }
  
    // Append notification to container
    const container = document.getElementById('notification-container');
    container.appendChild(notification);
  
    
  
  
    // Animate in
    setTimeout(() => {
      notification.style.opacity = '1';
    }, 10);
  
    
    if(timeout != 'no')
    {
      // Hide after timeout
      setTimeout(() => {
        hideNotification(notification);
      }, timeout);
    }
      
    if(dismiss === 'close')
    {
      // Allow manual dismissal
      closeButton.addEventListener('click', () => {
        hideNotification(notification);
      });
      // Allow dismissal by clicking anywhere
      notification.addEventListener('click', () => {
        hideNotification(notification);
      });
    }
    else if(dismiss === 'no' || dismiss === false)
    {
  
  
  
    }
    else {
     
    }
  
    notification.classList.add("animate__animated","animate__flash")
    return notification; // Return the notification element for further customization or reference
  }
  
  function hideNotification(notification) {
    if(!notification) {
      return
    }
    // Animate out
    notification.style.opacity = '0';
  
    // Remove element from the DOM after animation
    setTimeout(() => {
      if (notification.parentNode) {
        notification.parentNode.removeChild(notification);
      }
    }, 300);
  }
  
  function startProgressBar(progressBarContainer=false) {
    if(!progressBarContainer)
      progressBarContainer = document.getElementById('progress-bar-container');
    window.siteUi.showLoadingEffects();
    window.siteUi.simulatePageLoading()    
    if(progressBarContainer)
      progressBarContainer.style.display = 'block';
  }
  
  /*
  <ul class="pagination">
  <li class="page-item active">
      <a data-remote="true" class="page-link">2</a>
    </li>
  </ul>
  */
  function bindRemotePager() {
  $("ul.pagination li.page-item a[data-remote='true'].page-link").each(function(idx,el) {
    $(el).on('click',function(e) {
      const refreshingMessage = { header: 'Updating data', message: `New page data is loading`, type: 'starting', imageUrl: null } 
      let showNotice = siteUi.showNotification(refreshingMessage,3000);
  
    })
  })
  
  
  }
  
  // Exports
  window.siteUi = {
    loadPage,bindRemotePager,
    debounce,getRemotePage,loadGet,
    bindLoveBtn,bindLovePostBtn,bindLoveMessageBtn,showSiteOffer,setupShowBestOffer,bindMainMenu,scrollToEl,getElementStyle,
    hlProfiles,hlProfileByIdx,
    hlProfileToggle,
    hlProfileToggleMobile,
    setupOnboarder,
    expanderDoExpand,
    simulatePageLoading,
    bindBlogSections,bindDraggablePhotos,
    showPageOverlay,
    hidePageOverlay,
    showLoadingEffects,
    hideLoadingEffects,
    showNotification,
    hideNotification,
    startProgressBar
  };